import { OptionalInterface } from "../../../types/utils";

export interface VirtualDriveConfigOptions {

}

export class VirtualDriveConfig {
	constructor(options: OptionalInterface<VirtualDriveConfigOptions> = {}) {
		// const { } = options as VirtualDriveConfigOptions;
	}
}